import { useState, useEffect } from 'react';

import { isBrowser } from '..';

export default function useTransitionDelay(delay) {
  const [transitionDelay, setDelay] = useState();

  useEffect(() => {
    if (isBrowser) {
      setDelay({ transitionDelay: delay + 's' });
    }
  }, [delay]);

  return transitionDelay;
}
