import Cookies from 'universal-cookie';

const cookies = new Cookies();

const writeKey =
  process.env.NODE_ENV === 'production' && process.env.SEGMENT_PRODUCTION_WRITE_KEY
    ? process.env.SEGMENT_PRODUCTION_WRITE_KEY
    : process.env.SEGMENT_DEV_WRITE_KEY;

// Initializing helper for segment
export const initSegment = () => {
  if (cookies.get('diem-analytics-permissions') && writeKey) {
    window.analytics.load(writeKey);
    window.SegmentIntialized = true;
  }
};
