import { basic } from './';

function lockBodyScroll() {
  let scrollPosY = 0;
  let isLocked = false;

  function lock() {
    if (!isLocked) {
      scrollPosY = basic.getScrollTop();
      document.body.style.position = 'fixed';
      document.body.style.overflowY = 'scroll';
      document.body.style.marginTop = `-${scrollPosY}px`;
      isLocked = true;
    }
  }

  function unlock(skipPositionRestore = false) {
    if (isLocked) {
      document.body.style.position = '';
      document.body.style.overflowY = '';
      document.body.style.marginTop = '';
      if (!skipPositionRestore) {
        window.scrollTo(0, scrollPosY);
      }
      isLocked = false;
    }
  }

  return { isLocked, lock, unlock };
}

export default lockBodyScroll();
