import React, { memo, useCallback, useState, useEffect } from 'react';
import checkExtraProps from '@jam3/react-check-extra-props';

import './LanguageSelector.scss';

import { getSanitizedPathName } from '../../../util';
import { locales } from '../../../util/locales';

function LanguageSelector({ lang, languages, globalOptions }) {
  const [currentLocale, setLocale] = useState();

  useEffect(() => setLocale(lang), [lang]);

  const onChange = useCallback(
    (event) => {
      const locale = event.target.value;
      const sanitizedPathName = getSanitizedPathName();
      let newPathname = sanitizedPathName.replace(currentLocale, locale);

      if (currentLocale === 'en-US' && !newPathname.includes(locale)) {
        newPathname = locale + newPathname;
      }

      window.location.href = newPathname + '?noredirect=1';
    },
    [currentLocale],
  );

  return (
    <li className="LanguageSelector">
      <label className="language-label">
        {globalOptions.globalCopy.languageSelector + ': '}
        <select value={currentLocale} className="language-selector" name="language-selector" onChange={onChange}>
          {locales.map((locale) => (
            <option key={locale} value={locale}>
              {languages[locale]}
            </option>
          ))}
        </select>
      </label>
    </li>
  );
}

LanguageSelector.propTypes = checkExtraProps({});

LanguageSelector.defaultProps = {};

export default memo(LanguageSelector);
