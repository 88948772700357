import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkExtraProps from '@jam3/react-check-extra-props';
import { ReactComponent as OpenNav } from '../../assets/svgs/nav-elipsis.svg';
import { ReactComponent as CloseNav } from '../../assets/svgs/nav-close.svg';
import './HamburgerButton.scss';

function HamburgerButton({ color, handleClick, isOpen }) {
  return (
    <div className={classnames('HamburgerButton', { isOpen, [color]: true })} onClick={handleClick}>
      {isOpen ? <CloseNav /> : <OpenNav />}
    </div>
  );
}

HamburgerButton.propTypes = checkExtraProps({
  className: PropTypes.string,
  color: PropTypes.oneOf(['purple', 'white']),
  handleClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
});

HamburgerButton.defaultProps = {};

export default memo(HamburgerButton);
