import Cookies from 'universal-cookie';
import { initSegment } from './src/util/init-segment.js';

require('normalize.css');
require('./src/assets/style/global.scss');

const cookies = new Cookies();

export const onClientEntry = () => {
  initSegment();
};

const checkIfSegmentIsInitilized = () => !!window.SegmentIntialized;

export const onRouteUpdate = ({ location, ...props }) => {
  if (!checkIfSegmentIsInitilized()) initSegment();

  if (cookies.get('diem-analytics-permissions') && window.analytics) {
    window.analytics.page({
      title: document.title,
      url: location.href,
      path: location.pathname,
    });
  }

  const { pathname } = location;

  if (pathname.match(/\/cla-sign\/?$/)) {
    onCLASignRouteUpdate({ location });
  }
};

function onCLASignRouteUpdate({ location }) {
  const match = location.search.match(/pull=([^&]*)/);
  if (match && match.length > 1) {
    const pullNumber = match[1];
    if (window.localStorage) {
      window.localStorage.setItem('cla_pull', pullNumber);
    }
  }
}

if (process.env.LOCALE) {
  window.general = {
    lang: process.env.LOCALE.toLowerCase(),
  };
} else {
  window.general = {
    lang: 'en-us',
  };
}

// const isProduction = process.env.NODE_ENV === 'production';
//
//
// const JAM3_INTERNAL_FB_PIXEL = '2142572455792450';
// const JAM3_INTERNAL_SEGMENT_WRITE_KEY = '4o1O3LLd7EvFJ2Cp3CbFfXk3yy8LeT5t';
//
// // FOR SEGMENT WITH GOOGLE ANALYTICS:
// // ensure that the Website URL on
// // https://app.segment.com/<segment-workspace-name>/sources/javascript/settings/basic
// // is set to the correct url for the environment (local: http://leo.local,
// // dev: https://libra-develop.dfuzr.dev, production: https://libra.org, etc)
//
// // prettier-ignore
// function initFacebookPixel(id) {
//   /* eslint-disable no-unused-expressions */
//   !function(f,b,e,v,n,t,s)
//   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
//     n.callMethod.apply(n,arguments):n.queue.push(arguments)};
//     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
//     n.queue=[];t=b.createElement(e);t.async=!0;
//     t.src=v;s=b.getElementsByTagName(e)[0];
//     s.parentNode.insertBefore(t,s)}(window, document,'script',
//     'https://connect.facebook.net/en_US/fbevents.js');
//   window.fbq.disablePushState = true;
//   window.fbq('init', id);
// }
//
// // prettier-ignore
// function initSegmentAnalytics(writeKey) {
//   !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
//     analytics.load(writeKey);
//   }}();
// }
//
//
// if (!isProduction) {
//   // local development
//   window.general = require('./mock-data/general');
//   try {
//     const sanitizedPathName = getSanitizedPathName();
//     window.general.lang = /^\/(?<lang>\w{2}-\w{2})/.exec(sanitizedPathName).groups.lang;
//   } catch (error) {
//     window.general.lang = 'en-US';
//   }
//
//   document.documentElement.lang = window.general.lang;
//
//   document.documentElement.classList.add('enable-animations');
//
//   initFacebookPixel(JAM3_INTERNAL_FB_PIXEL);
//   initSegmentAnalytics(JAM3_INTERNAL_SEGMENT_WRITE_KEY);
// } else {
//   // built environment
//   if (getDangerousUnsanitizedHostName() === 'localhost') {
//     // local build with `npm run serve`
//     initFacebookPixel(JAM3_INTERNAL_FB_PIXEL);
//     initSegmentAnalytics(JAM3_INTERNAL_SEGMENT_WRITE_KEY);
//   } else {
//     // deployed wordpress environment
//     // Cookie banner implementation should run only in a wordpress environment
//     const productionHostName = 'libra.org';
//
//     const FB_PIXEL =
//       getDangerousUnsanitizedHostName() === productionHostName
//         ? window.general.global_settings.fb_pixel.fb_pixel_live
//         : window.general.global_settings.fb_pixel.fb_pixel_develop;
//
//     const SEGMENT_WRITE_KEY =
//       getDangerousUnsanitizedHostName() === productionHostName
//         ? window.general.global_settings.segment_analytics.js_write_key_live
//         : window.general.global_settings.segment_analytics.js_write_key_develop;
//
//     const cookiesArray = document.cookie.split('; ');
//
//     if (cookiesArray.includes('jam3_cookie_closed=true')) {
//       initFacebookPixel(FB_PIXEL);
//       initSegmentAnalytics(SEGMENT_WRITE_KEY);
//     } else {
//       // NOTE: Event that we need to track FB Pixel Jam3 Plugin
//       document.addEventListener('cookieBannerStartTracking', function() {
//         initFacebookPixel(FB_PIXEL);
//         initSegmentAnalytics(SEGMENT_WRITE_KEY);
//       });
//     }
//   }
// }
//
// function onceRouteReady(callback) {
//   // wrap inside two rAF calls to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/9139)
//   // the first rAF call is work done by react-helmet.
//   // once completed, the document title should be correct, then the page view can be executed in the second rAF call.
//   // reactHelmet is using requestAnimationFrame so we should use it too: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
//   // see https://github.com/gatsbyjs/gatsby/pull/10917 for details.
//   if (`requestAnimationFrame` in window) {
//     requestAnimationFrame(() => {
//       requestAnimationFrame(callback);
//     });
//   } else {
//     // simulate 2 rAF calls
//     setTimeout(callback, 32);
//   }
// }
//
// function onRouteUpdate({ location, ...props }) {
// if (window.analytics && typeof window.analytics.page === 'function') {
//   onceRouteReady(() => {
//     window.analytics.page({
//       title: document.title,
//       url: location.href,
//       path: location.pathname,
//     });
//   });
// }
//
// const { pathname } = location;
//
// if (pathname.match(/\/cla-sign\/?$/)) {
//   onCLASignRouteUpdate({ location });
// }
// }
//
// function onCLASignRouteUpdate({ location }) {
//   const match = location.search.match(/pull=([^&]*)/);
//   if (match && match.length > 1) {
//     const pullNumber = match[1];
//     if (window.localStorage) {
//       window.localStorage.setItem('cla_pull', pullNumber);
//     }
//   }
// }
//
// module.exports = {
//   onRouteUpdate,
// };
