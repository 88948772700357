import BezierEasing from 'bezier-easing';

const ease = {
  linear: new BezierEasing(0.25, 0.25, 0.75, 0.75),
  easeOutCubic: new BezierEasing(0.215, 0.61, 0.355, 1),
  ease1: new BezierEasing(0.17, 0.17, 0.05, 1),
  ease2: new BezierEasing(0.31, 0, 0.13, 1),
  ease3: new BezierEasing(0.37, 0, 0.83, 0.83),
  ease4: new BezierEasing(0.4, 0, 0.13, 1),
  ease5: new BezierEasing(0.14, 0, 0.11, 1),
  ease6: new BezierEasing(0.2, 0.57, 0.5, 1),
  ease7: new BezierEasing(0.37, 0, 0.63, 1),
  ease8: new BezierEasing(0.2, 0.8, 0.2, 1.1),
  ease9: new BezierEasing(0.41, 0, 0.16, 1),
  ease10: new BezierEasing(0.17, 0.17, 0.16, 1),
  ease11: new BezierEasing(0.66, 0, 0.83, 0.83),
  ease12: new BezierEasing(0.67, 0, 0.75, 1),
  ease13: new BezierEasing(0.6, 0, 0.53, 1),
  ease14: new BezierEasing(0.42, 0, 0.58, 1),
};

export default ease;
