import sanitizer from './sanitizer';
import {
  getSanitizedHash,
  getSanitizedPathName,
  getSanitizedHostName,
  getDangerousUnsanitizedHash,
  getDangerousUnsanitizedPathName,
  getDangerousUnsanitizedHostName,
} from './window-location-props';
import lockBodyScroll from './lock-body-scroll';
import * as customPropTypes from './custom-prop-types';
import * as basic from './basic-functions';
import useCSSMotion from './motion-manager/use-css-motion-hook';
import useGsapMotion from './motion-manager/use-gsap-motion-hook';
import useParallaxMotion, { parallaxEventListener } from './motion-manager/use-parallax-motion-hook';
import useWindowSize from './motion-manager/use-window-size';
import useIntersectionObserver from './motion-manager/use-intersection-observer-hook';
import { track, trackPageView, trackNavigationClick, useCTAClickTracking, useSectionTimeSpent } from './track';
import ease from './motion-manager/ease';
import hashManager from './hash';
import useTransitionDelay from './motion-manager/use-transition-delay-hook';
import { useWindowClick, useWindowEvent, useWindowScroll, useNavBarScrollState } from './custom-hooks';
import renderDateRange from './render-date-range';
import * as errorMessages from './error-messages';

const isBrowser = typeof window !== `undefined`;

export {
  errorMessages,
  isBrowser,
  sanitizer,
  getSanitizedHash,
  getSanitizedPathName,
  getSanitizedHostName,
  getDangerousUnsanitizedHash,
  getDangerousUnsanitizedPathName,
  getDangerousUnsanitizedHostName,
  lockBodyScroll,
  customPropTypes,
  hashManager,
  basic,
  useCSSMotion,
  useGsapMotion,
  ease,
  useWindowSize,
  useParallaxMotion,
  parallaxEventListener,
  useIntersectionObserver,
  renderDateRange,
  track,
  trackPageView,
  trackNavigationClick,
  useCTAClickTracking,
  useSectionTimeSpent,
  useTransitionDelay,
  useWindowClick,
  useWindowEvent,
  useWindowScroll,
  useNavBarScrollState,
};
