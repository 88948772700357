import React, { memo, useRef, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import NavBar from './NavBar';
import { useNavBarScrollState } from '../../util';
import { PathnameContext } from '../Layout/Layout';

function NavBarContainer({ double }) {
  const ref = useRef();
  const scrollState = useNavBarScrollState(ref);
  const pathname = useContext(PathnameContext);
  const [isOpen, setIsOpen] = useState(false);

  function toggleIsOpen(state) {
    setIsOpen(state !== undefined ? state : !isOpen);
  }
  return (
    <>
      <NavBar ref={ref} sticky {...scrollState} pathname={pathname} onToggleClick={toggleIsOpen} isOpen={isOpen} />
      {double && <NavBar white={false} hidden onToggleClick={toggleIsOpen} isOpen={isOpen} />}
    </>
  );
}

NavBarContainer.propTypes = checkExtraProps({
  double: PropTypes.bool, // should have the extra transparent navbar on top of the top (hero) section
});

NavBarContainer.defaultProps = {};

export default memo(NavBarContainer);
