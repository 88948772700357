import DOMPurify from 'dompurify';
import { isBrowser } from './';

// if (isBrowser) {
//   DOMPurify.addHook('uponSanitizeElement', (node, data) => {
//     if (data.tagName === 'iframe') {
//       const src = node.getAttribute('src') || '';
//       if (!src.startsWith('https://www.youtube.com/embed/')) {
//         return node.parentNode?.removeChild(node);
//       }
//     }
//   });
// }

const htmlOptions = {
  ADD_TAGS: ['iframe'],
  ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'src', 'style'],
  FORBID_ATTR: ['border'],
};

export default function sanitizer(
  dirtyInput,
  isHtml = false,
  options = { ALLOWED_ATTR: ['href', 'rel', 'target', 'class', 'id'] },
) {
  return isBrowser
    ? DOMPurify.sanitize(dirtyInput, isHtml ? Object.assign(htmlOptions, options) : options) + ''
    : dirtyInput;
}
