import { TweenMax } from 'gsap/all';
import { trackNavigationClick, getSanitizedHash, getDangerousUnsanitizedHash } from '.';

function hashManager() {
  let shouldHashChange = true;

  function changeURL(hash) {
    if (window.history.replaceState) {
      window.history.replaceState(null, null, `#${hash}`);
    }
  }

  function changeByClick(event, id) {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      shouldHashChange = false;

      TweenMax.to(window, 1, {
        scrollTo: element,
        onComplete: () => {
          shouldHashChange = true;
          trackNavigationClick('Anchor_' + id);
        },
      });
      changeURL(id);
    }
  }

  function changeOnLoad(id) {
    const element = document.getElementById(id);
    if (element) {
      TweenMax.to(window, 0, {
        scrollTo: element,
      });
      changeURL(id);
    }
  }

  function changeByScroll(id) {
    shouldHashChange && changeURL(id);
  }

  function checkHashOnLoad() {
    if (getDangerousUnsanitizedHash()) {
      changeOnLoad(getSanitizedHash().replace('#', ''));
    }
  }

  function reset() {
    shouldHashChange && window.history.replaceState(null, null, ' ');
  }

  return { changeByClick, changeByScroll, changeOnLoad, checkHashOnLoad, reset };
}

export default hashManager();
