import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import CTA from './CTA';

function CTAButton({ onClick, ...ctaProps }) {
  return (
    <button className="CTA CTAButton" onClick={onClick} aria-labelledby="cta-text">
      <CTA {...ctaProps} />
    </button>
  );
}

CTAButton.propTypes = checkExtraProps({
  onClick: PropTypes.func.isRequired,
  ...CTA.type.propTypes,
});

CTAButton.defaultProps = { ...CTA.type.defaultProps };

export default memo(CTAButton);
