import moment from 'moment';

// TODO: has to be localized eventually
const renderDateRange = (beginDate, endDate) => {
  const momentBeginDate = moment(beginDate, 'YYYY/MM/DD');
  const momentEndDate = moment(endDate, 'YYYY/MM/DD');

  const sameMonth = momentBeginDate.month() === momentEndDate.month();
  const sameYear = momentBeginDate.year() === momentEndDate.year();

  if (momentBeginDate.isSame(momentEndDate)) {
    return `${momentBeginDate.format('MMM D, Y')}`;
  } else if (!sameYear) {
    return `${momentBeginDate.format('MMM D, Y')} - ${momentEndDate.format('MMM D, Y')}`;
  } else if (!sameMonth) {
    return `${momentBeginDate.format('MMM D')} - ${momentEndDate.format('MMM D, Y')}`;
  }

  // same year and same month
  return `${momentBeginDate.format('MMM D')} - ${momentEndDate.format('D, Y')}`;
};

export default renderDateRange;
