import React, { memo } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import LanguageSelector from './LanguageSelector/LanguageSelector';

import './TinyFooter.scss';

import { trackNavigationClick } from '../../util';

function TinyFooter() {
  const {
    legal,
    wp: {
      footer: {
        globalOptionsFooter: { copyright, wordpressAttribution },
      },
    },
  } = useStaticQuery(graphql`
    {
      legal: wpMenu(locations: { eq: LEGAL }) {
        name
        menuItems {
          nodes {
            label
            url
            path
            databaseId
          }
        }
      }
      wp {
        footer {
          globalOptionsFooter {
            copyright
            wordpressAttribution
          }
        }
      }
    }
  `);
  return (
    <div className="TinyFooter">
      <ul className="left-side">
        <li>{copyright}</li>
        <li>{wordpressAttribution}</li>
      </ul>
    </div>
  );
}

TinyFooter.propTypes = checkExtraProps({
  className: PropTypes.string,
});

TinyFooter.defaultProps = {};

export default memo(TinyFooter);
