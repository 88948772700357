import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { graphql, useStaticQuery } from 'gatsby';
import { initSegment } from '../../util/init-segment.js';
import { sanitizer } from '../../util';

import './CookieBanner.scss';

const CookieBanner = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          cookieBanner {
            cookieBannerOptions {
              acceptCopy
              bodyCopy
              declineCopy
              duration
              title
            }
          }
        }
      }
    `,
  );

  const { acceptCopy, bodyCopy, declineCopy, duration, title } = wp?.cookieBanner?.cookieBannerOptions;

  return (
    <CookieConsent
      buttonClasses="cookie-button cookie-accept"
      buttonText={acceptCopy}
      contentClasses="cookie-content container"
      cookieName="diem-analytics-permissions"
      declineButtonClasses="cookie-button cookie-decline"
      declineButtonText={declineCopy}
      enableDeclineButton
      expires={Number(duration) || 365}
      flipButtons
      location="bottom"
      onAccept={() => initSegment()}
      sameSite="strict"
    >
      <h2 className="title">{title}</h2>
      <div dangerouslySetInnerHTML={{ __html: sanitizer(bodyCopy, true, {}) }} />
    </CookieConsent>
  );
};

export default CookieBanner;
