import React, { useState, createContext, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { isBrowser, trackPageView, track } from '../../util';
import NavBar from '../NavBar/NavBarContainer';
import Footer from '../Footer/Footer';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import CookieBanner from '../CookieBanner/CookieBanner';

require('../../util/motion-manager/plugins');

import './Layout.scss';

export const ModalContext = createContext();
export const PathnameContext = createContext();

function Layout({ children, pageContext, location: { pathname } }) {
  const { meta, isHome } = pageContext;
  const [videoId, setVideoId] = useState(null);
  const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);

  const pageTimeSpent = useRef(null);

  const trackExternalLinkClick = useCallback((event) => {
    if (
      event.target instanceof HTMLAnchorElement &&
      event.target.protocol.startsWith('http') &&
      event.target.host !== window.location.host
    ) {
      track('OutboundClick', { name: event.target.href });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', trackExternalLinkClick);
    return () => window.removeEventListener('click', trackExternalLinkClick);
  }, [trackExternalLinkClick]);

  useEffect(() => {
    if (meta && meta.title) document.title = meta.title;
  }, [meta]);

  useEffect(() => {
    const now = new Date();
    if (pageTimeSpent.current) {
      const [page, timeStart] = pageTimeSpent.current;
      const time = Math.round((now - timeStart) / 1000);
      track('PageTimeSpent', { page, time });
    }
    pageTimeSpent.current = [pathname, now];

    trackPageView();
  }, [pathname]);

  useEffect(() => {
    setVideoPlayerOpen(false);
  }, [pathname]);

  return (
    <ModalContext.Provider value={{ setVideoId, setVideoPlayerOpen }}>
      <PathnameContext.Provider value={pathname}>
        <NavBar double={isHome} />
        {children}
        <Footer />
        {isBrowser && (
          <VideoPlayer videoPlayerOpen={videoPlayerOpen} videoId={videoId} setVideoPlayerOpen={setVideoPlayerOpen} />
        )}
        <CookieBanner />
      </PathnameContext.Provider>
    </ModalContext.Provider>
  );
}

Layout.propTypes = checkExtraProps(
  {
    pageContext: PropTypes.shape({
      slug: PropTypes.string,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  },
  ['path', '*', 'uri', 'navigate', 'pageResources', 'data'],
);

export default Layout;
