import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import classnames from 'classnames';

import { ReactComponent as Arrow } from '../../assets/svgs/arrow.svg';
import { ReactComponent as Play } from '../../assets/svgs/play.svg';
import { ReactComponent as Close } from '../../assets/svgs/close.svg';

import { customPropTypes } from '../../util';
import './CTA.scss';

const iconComponents = {
  arrow: Arrow,
  play: Play,
  close: Close,
};

function CTA({ motionState, text, largeText, white, icon, hiddenText }) {
  const Icon = iconComponents[icon];

  return (
    <span className={classnames('cta-content', { white, largeText }, motionState)}>
      <span id="cta-text" className="text" hidden={hiddenText}>
        {text}
      </span>
      <span className={classnames('icon', icon)}>
        <Icon aria-hidden="true" focusable="false" />
      </span>
    </span>
  );
}

CTA.propTypes = checkExtraProps({
  motionState: customPropTypes.motionStatePropTypes,
  text: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(['arrow', 'play', 'close']),
  white: PropTypes.bool,
});

CTA.defaultProps = {
  icon: 'arrow',
};

export default memo(CTA);
