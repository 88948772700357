import React, { memo, useEffect, useRef, useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import YouTubePlayer from 'react-player';
// import FacebookPlayer from 'react-player/lib/players/Facebook';

import './VideoPlayer.scss';

import { lockBodyScroll, track } from '../../util';

import CTAButton from '../CTA/CTAButton';

function VideoPlayer({ videoId, videoPlayerOpen, setVideoPlayerOpen }) {
  const videoRef = useRef(null);

  useEffect(() => {
    videoPlayerOpen ? lockBodyScroll.lock() : lockBodyScroll.unlock();
  }, [videoPlayerOpen]);

  const handleClose = useCallback(
    (event) => {
      event.stopPropagation();

      if (event.target !== videoRef.current) {
        setVideoPlayerOpen(false);
      }

      const stopTime = videoRef.current.getCurrentTime();
      track('VideoWatchTime', { video: videoId, value: Math.round(stopTime) });
    },
    [setVideoPlayerOpen, videoId],
  );

  const handleStart = useCallback(() => {
    track('VideoView', { video: videoId });
  }, [videoId]);

  return (
    <div className={classnames('VideoPlayer', { 'video-player-open': videoPlayerOpen })} onClick={handleClose}>
      <div className="container">
        <CTAButton text="Close" white icon="close" hiddenText onClick={handleClose} />
        <div className="iframe-container">
          <div className="video-background" />

          {videoPlayerOpen && (
            <YouTubePlayer
              ref={videoRef}
              className="react-player"
              url={videoId ? `https://www.youtube.com/watch?v=${videoId}` : null}
              config={{
                youtube: {
                  playerVars: { rel: 0 },
                },
              }}
              playing={videoPlayerOpen}
              controls
              width="100%"
              height="100%"
              onStart={handleStart}
              stopOnUnmount={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}

VideoPlayer.propTypes = checkExtraProps({
  videoId: PropTypes.string,
  videoPlayerOpen: PropTypes.bool,
  setVideoPlayerOpen: PropTypes.func,
});

VideoPlayer.defaultProps = {};

export default memo(VideoPlayer);
