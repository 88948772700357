import { useEffect } from 'react';

export function useWindowEvent(event, callback) {
  // componentDidMount
  useEffect(() => {
    window.addEventListener(event, callback);
    // componentWillUnmount
    return () => window.removeEventListener(event, callback);
  }, [event, callback]);
}

export function useWindowScroll(callback) {
  return useWindowEvent('scroll', callback);
}

export function useWindowClick(callback) {
  return useWindowEvent('click', callback);
}
