// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-content-types-blog-js": () => import("./../../../src/templates/content-types/blog.js" /* webpackChunkName: "component---src-templates-content-types-blog-js" */),
  "component---src-templates-content-types-page-js": () => import("./../../../src/templates/content-types/page.js" /* webpackChunkName: "component---src-templates-content-types-page-js" */),
  "component---src-templates-content-types-update-js": () => import("./../../../src/templates/content-types/update.js" /* webpackChunkName: "component---src-templates-content-types-update-js" */)
}

