import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { Link } from 'gatsby';

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const GatsbyLink = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <Link to={to} activeClassName={activeClassName} partiallyActive={partiallyActive} {...other}>
        {children}
      </Link>
    );
  }
  // External
  return (
    <a href={to} rel="noopener noreferrer" {...other}>
      {children}
    </a>
  );
};

GatsbyLink.propTypes = checkExtraProps({
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  activeClassName: PropTypes.string,
  'aria-label': PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  target: PropTypes.string, // used by php to determine if link is external on initial load
  activeMenuName: PropTypes.string, // give active class if link within activeMenuName is active
});

export default memo(GatsbyLink);
