import { useState, useEffect, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { isBrowser } from '../index';

function getSize() {
  return {
    innerHeight: isBrowser ? window.innerHeight : 0,
    innerWidth: isBrowser ? window.innerWidth : 0,
  };
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize());

  const [handleResize] = useDebouncedCallback(
    useCallback(() => {
      setWindowSize(getSize());
    }, []),
    250,
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return windowSize;
}
