import PropTypes from 'prop-types';
import { MOTION_STATE } from './motion-manager/constant';

export const image = PropTypes.shape({
  localFile: PropTypes.any.isRequired,
  altText: PropTypes.string.isRequired,
});

export const responsiveImage = PropTypes.shape({
  default: image.isRequired,
  phone: image,
});

export const video = PropTypes.shape({
  video: PropTypes.shape({
    videoId: PropTypes.string,
    poster: responsiveImage,
  }),
});

export const link = PropTypes.shape({
  url: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
});

export const videoPropTypes = PropTypes.shape({
  type: PropTypes.oneOf(['video']).isRequired,
  video: video.isRequired,
});

export const motionStatePropTypes = PropTypes.shape({
  [MOTION_STATE.init]: PropTypes.bool,
  [MOTION_STATE.in]: PropTypes.bool,
});

export const pageInfo = PropTypes.shape({
  hasNextPage: PropTypes.bool,
  hasPreviousPage: PropTypes.bool,
  currentPage: PropTypes.number,
  pageCount: PropTypes.number,
});

export const basePropTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  jumpToLinkTitle: PropTypes.string,
};
