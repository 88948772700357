import React, { memo, forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import classnames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyLink from '../GatsbyLink/GatsbyLink';
import { useWindowClick } from '../../util';

import './NavBar.scss';
import { ReactComponent as WhitePaperIcon } from '../../assets/svgs/whitepaper.svg';
import Logo from '../Logo/Logo';
import HamburgerButton from '../HamburgerButton/HamburgerButton';
import { lockBodyScroll, trackNavigationClick } from '../../util';

// Warning: Do not use directly! Always import NavBarContainer instead in order to add/use a NavBar
function NavBar({ sticky, white, scrollingUp, shouldStickyAppear, hidden, pathname, onToggleClick, isOpen }, ref) {
  const { header, highlight } = useStaticQuery(graphql`
    {
      header: wpMenu(locations: { eq: HEADER }) {
        name
        menuItems {
          nodes {
            label
            url
            path
            databaseId
          }
        }
      }

      highlight: wpMenu(locations: { eq: HIGHLIGHT }) {
        name
        menuItems {
          nodes {
            label
            url
            path
            databaseId
          }
        }
      }
    }
  `);

  const [isHamburgerOpen, setHamburgerOpen] = useState(false);

  useEffect(() => {
    lockBodyScroll.unlock(true);
    setHamburgerOpen(false);
    return () => {
      lockBodyScroll.unlock(true);
    };
  }, [pathname]);

  useWindowClick(windowClick);

  function toggleMenu(e) {
    e.stopPropagation();
    !isHamburgerOpen ? lockBodyScroll.lock() : lockBodyScroll.unlock();
    setHamburgerOpen(!isHamburgerOpen);
    onToggleClick();
  }

  function closeMenu() {
    setHamburgerOpen(false);
    lockBodyScroll.unlock();
    onToggleClick(false);
  }

  function windowClick(e) {
    closeMenu();
  }

  // TODO: In general clean up all the links. There is a lot of fields, that are not mandatory.

  return (
    <nav
      className={classnames('NavBar', {
        sticky,
        white,
        scrollingUp,
        shouldStickyAppear,
        isHamburgerOpen: isHamburgerOpen || isOpen,
      })}
      aria-hidden={hidden}
      ref={ref}
    >
      <div className="topNavWrapper container">
        <HamburgerButton
          color={isHamburgerOpen || white ? 'purple' : 'white'}
          isOpen={isHamburgerOpen}
          handleClick={toggleMenu}
        />
        {/* TODO: internationalize the link */}
        <GatsbyLink
          className="logo-container"
          onClick={() => trackNavigationClick('Header_Home')}
          aria-label="Diem logo"
          to="/"
        >
          <Logo />
        </GatsbyLink>
        <ul className="nav-site nav-site-internal" onClick={(e) => e.stopPropagation()}>
          {header?.menuItems?.nodes.map(({ url, label, target }) => {
            return (
              <li key={label} onClick={closeMenu}>
                <GatsbyLink
                  to={url}
                  activeClassName="active"
                  partiallyActive={true}
                  onClick={() => trackNavigationClick(`Header_${label}`)}
                  target={target}
                >
                  {label}
                </GatsbyLink>
              </li>
            );
          })}
          {highlight?.menuItems?.nodes.map(({ url, label, target }) => (
            <li className="highlight" key={label}>
              <GatsbyLink
                to={url}
                activeClassName="active"
                partiallyActive={true}
                onClick={() => trackNavigationClick(`Header_${label}`)}
                target={target}
              >
                {label}
              </GatsbyLink>
            </li>
          ))}
        </ul>
        {highlight?.menuItems?.nodes.map(({ url, label, target }) => (
          <div className="whitepapericon" key={label}>
            <GatsbyLink to={url} onClick={() => trackNavigationClick(`Header_${label}`)} target={target}>
              <WhitePaperIcon className={classnames({ white: !white && !isHamburgerOpen })} />
              <div className="whitepaper-text">{label}</div>
            </GatsbyLink>
          </div>
        ))}
      </div>
    </nav>
  );
}

const FinalNavBar = memo(forwardRef(NavBar));

FinalNavBar.propTypes /* remove-proptypes */ = checkExtraProps({
  sticky: PropTypes.bool,
  white: PropTypes.bool,
  scrollingUp: PropTypes.bool,
  shouldStickyAppear: PropTypes.bool,
  hidden: PropTypes.bool,
  pathname: PropTypes.string,
  onToggleClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
});

FinalNavBar.defaultProps = {
  sticky: false,
  white: true,
};

export default FinalNavBar;
