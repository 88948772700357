export function getScrollTop() {
  return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
}

export function getMarginTop() {
  const elemStyle = window.getComputedStyle(document.body);
  return parseFloat(elemStyle.getPropertyValue('margin-top').slice(0, -2), 10);
}

export function isInBetween(number, min, max) {
  return number >= min && number < max;
}

// REFERENCE: https://rosettacode.org/wiki/Map_range#JavaScript
export function mapRange(number, in_min, in_max, out_min, out_max) {
  return out_min + ((number - in_min) * (out_max - out_min)) / (in_max - in_min);
}

export function replaceWhiteSpace(str, char = '_') {
  return str.replace(/\s/g, char);
}
