import { useEffect, useState } from 'react';
import { TweenMax } from 'gsap/all';
import { useDebouncedCallback } from 'use-debounce';

import { isBrowser, ease } from '../../util';
export const parallaxEventListener = isBrowser ? new Event('parallax') : undefined;

function useParallaxMotion(ref, parallaxDirectionReverse = false, scale = 1.2) {
  const maxMovementPercentage = ((scale - 1) / 2) * 100;
  const [intersecting, setIntersecting] = useState(false);

  const [parallaxMovement] = useDebouncedCallback(
    () => {
      const windowHeight = window.innerHeight;
      const { y, height } = ref.current.getBoundingClientRect();

      const windowHeightHalf = windowHeight / 2;
      const refHeightHalf = height / 2;

      const pxDistanceRefMiddleFromWindowMiddle = y + refHeightHalf - windowHeightHalf;
      const percentageDistanceRefMiddleFromWindowMiddle =
        pxDistanceRefMiddleFromWindowMiddle / (windowHeightHalf + refHeightHalf);

      let parallaxMovement = percentageDistanceRefMiddleFromWindowMiddle * maxMovementPercentage;
      parallaxMovement = parallaxDirectionReverse ? parallaxMovement * -1 : parallaxMovement;

      TweenMax.to(ref.current.childNodes[0], 0.15, {
        ease: ease.easeOutCubic,
        yPercent: Math.min(Math.max(parallaxMovement, maxMovementPercentage * -1), maxMovementPercentage),
        force3D: true,
      });
    },
    50,
    { maxWait: 50 },
  );

  useEffect(() => {
    const { current } = ref;

    TweenMax.set(current.childNodes[0], {
      scale: scale,
      yPercent: parallaxDirectionReverse ? maxMovementPercentage * -1 : maxMovementPercentage,
      force3D: true,
    });

    const intersectionObserver = new IntersectionObserver(function (entries) {
      if (entries[0].isIntersecting || entries[0].intersectionRatio > 0) {
        setIntersecting(true);
      } else {
        setIntersecting(false);
      }
    });

    intersectionObserver.observe(current);

    return () => {
      intersectionObserver.unobserve(current);
    };
  }, [ref, maxMovementPercentage, scale, parallaxDirectionReverse]);

  useEffect(() => {
    if (intersecting) {
      // parallaxMovement();
      window.addEventListener('parallax', parallaxMovement);
      window.addEventListener('scroll', parallaxMovement);
      window.addEventListener('resize', parallaxMovement);
    }

    return () => {
      window.removeEventListener('parallax', parallaxMovement);
      window.removeEventListener('scroll', parallaxMovement);
      window.removeEventListener('resize', parallaxMovement);
    };
  }, [parallaxMovement, intersecting]);
}

export default useParallaxMotion;
