import React, { memo, useRef } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import './Footer.scss';

import Logo from '../Logo/Logo';
import TinyFooter from '../TinyFooter/TinyFooter';

import { trackNavigationClick, track, useSectionTimeSpent } from '../../util';
import GatsbyLink from '../GatsbyLink/GatsbyLink';

function Footer() {
  const containerRef = useRef(null);

  useSectionTimeSpent('Footer_Bottom', containerRef);

  const {
    footer,
    legal,
    wp: {
      footer: {
        globalOptionsFooter: { socialIcons },
      },
    },
  } = useStaticQuery(graphql`
    {
      footer: wpMenu(locations: { eq: FOOTER }) {
        name
        menuItems {
          nodes {
            label
            url
            path
            databaseId
          }
        }
      }
      legal: wpMenu(locations: { eq: LEGAL }) {
        name
        menuItems {
          nodes {
            label
            url
            path
            databaseId
          }
        }
      }
      wp {
        footer {
          globalOptionsFooter {
            socialIcons {
              image {
                ...SocialIcon
              }
              link
              title
            }
          }
        }
      }
    }
  `);

  return (
    <footer id="Footer" ref={containerRef}>
      <div className="container big-footer">
        <nav>
          <div>
            <Logo />
          </div>
          <div className="center-links-container">
            <ul className="footer-links">
              {footer?.menuItems?.nodes.map(({ url, label }) => (
                <li key={label}>
                  <GatsbyLink to={url} onClick={() => trackNavigationClick(`Footer_${label}`)}>
                    {label}
                  </GatsbyLink>
                </li>
              ))}
            </ul>
            <ul className="legal-links">
              {legal?.menuItems?.nodes.map(({ path, label }) => {
                return (
                  <li key={label}>
                    <Link to={path} onClick={() => trackNavigationClick(`Footer_${label}`)}>
                      {label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <ul className="social-links">
            {socialIcons?.map((icon, index) => {
              const { image, link, title } = icon;
              return (
                <li key={`social-${index}`}>
                  <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={title}
                    onClick={() => track('SocialClick', { name: `Social_${title}` })}
                  >
                    <img className="icon" src={image?.localFile.publicURL} alt={image?.altText || title || ''} />
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
      <div className="container tiny-footer">
        <TinyFooter />
      </div>
    </footer>
  );
}

export default memo(Footer);
