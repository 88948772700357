import { useCallback, useEffect, useRef } from 'react';

import { isBrowser, useIntersectionObserver, getDangerousUnsanitizedPathName } from '.';

export function fbq(...args) {
  if (isBrowser && typeof window.fbq === 'function') {
    window.fbq(...args);
  }
}

export function trackPageView() {
  fbq('track', 'PageView');

  // NOTE: With Segment, page views are tracked by onRouteUpdate
  // in gatsby-browser.js.
}

export function track(eventName, params) {
  params.page = params.page || getDangerousUnsanitizedPathName();
  fbq('trackCustom', eventName, params);

  if (isBrowser && window.analytics && typeof window.analytics.track === 'function') {
    window.analytics.track(eventName, params);
  }
}

export function trackNavigationClick(name) {
  track('NavigationClick', { name });
}

export function useCTAClickTracking(name) {
  return name ? useCallback(() => track('CTAClick', { name }), [name]) : null;
}

/**
 * React hook that manage section time spent analytics
 */
export function useSectionTimeSpent(section, containerRef) {
  const isSectionViewed = useRef(false);
  const sectionTimeSpent = useRef(null);
  const intersected = useIntersectionObserver(containerRef, { triggerOnce: false });

  const trackSectionTimeSpent = useCallback(() => {
    const now = new Date();
    const [section, timeStart] = sectionTimeSpent.current;
    const time = Math.round((now - timeStart) / 1000);

    if (time > 1) {
      track('SectionTimeSpent', { section, time });
    }

    sectionTimeSpent.current = null;
  }, []);

  useEffect(() => {
    if (!section) return;

    if (intersected && !sectionTimeSpent.current) {
      const now = new Date();

      sectionTimeSpent.current = [section, now];

      if (!isSectionViewed.current) {
        isSectionViewed.current = true;
        track('SectionView', { section });
      }
    }

    // NOTE: this will be executed when leaving the current section and the page.
    return () => {
      if (sectionTimeSpent.current) {
        trackSectionTimeSpent();
      }
    };
  }, [section, intersected, trackSectionTimeSpent]);
}
