export const localesWithoutTrailingSlash = [
  '/id-ID',
  '/zh-CN',
  '/en-US',
  '/fr-FR',
  '/de-DE',
  '/ja-JP',
  '/pt-BR',
  '/es-LA',
  '/ru-RU',
];

export const locales = ['id-ID', 'zh-CN', 'en-US', 'fr-FR', 'de-DE', 'ja-JP', 'pt-BR', 'es-LA', 'ru-RU'];
