import { sanitizer } from '.';

const WINDOW_ALLOWED_TYPES = [];

export function getSanitizedHash() {
  return sanitizer(window.location.hash, { ALLOWED_ATTR: WINDOW_ALLOWED_TYPES });
}

export function getSanitizedPathName() {
  return sanitizer(window.location.pathname, { ALLOWED_ATTR: WINDOW_ALLOWED_TYPES });
}

export function getSanitizedHostName() {
  return sanitizer(window.location.hostname, { ALLOWED_ATTR: WINDOW_ALLOWED_TYPES });
}

export function getDangerousUnsanitizedHash() {
  return window.location.hash;
}

export function getDangerousUnsanitizedPathName() {
  return window.location.pathname;
}

export function getDangerousUnsanitizedHostName() {
  return window.location.hostname;
}
